<template>
  <div class="square-container">
  </div>
</template>
<script>
import userInfo from '@/components/userInfo/index.vue'
import config from '@/utils/config'
import { getToken } from '@/utils/auth'
import { getRoomList } from '@/api/api'
export default {
  name: "square",
  components: {
    userInfo
  },
  data() {
    return {
      'sid':""
    }
  },
  methods: {
    joinRoom() {
      return this.$router.push({ path: `/metaScene?sid=${this.sid}`})
      if (getToken()) {
        this.$router.push({ path: `/metaScene?sid=${this.sid}`})
        // top.location.replace(`https://app.metaio.cc/${this.sid}?token=${getToken()}`);
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    
  },
  created() {},
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.sid = this.$route.params.sid
      this.uid = query.uid
      this.joinRoom()
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/square.scss';
::v-deep .el-dialog{
  margin: 0;
  position: unset;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding:0;
  }
}
</style>
